<template>
  <div class="soft-prolong-single">
    <base-prolong
      :title="tariff.domain"
      :subtitle="tariff.ip"
      :list="innerList"
      :text="isPast ? text : null"
      @ready="onReady"
      @change="onChange"
      @change-total="onChangeTotal"
    />
    <div>
      <div class="title standart-title error-color">
        {{ $t('alarm') }}
      </div>
      <div class="title standart-title standart-color">
        {{ $t('text') }}
      </div>
      <paymethod-list-block
        :config="config"
        class="debit-card"
        @change="onPayments"
      ></paymethod-list-block>
      <div v-if="isView" class="debit-card-add" @click="addCard">
        {{ $t('add') }}
      </div>
    </div>
  </div>
</template>

<script>
import PaymethodListBlock from '@/components/Configurator/components/PaymethodListBlock.vue';
import { SoftTariff } from '@/models/BillMgr/SoftTariff';
import BaseProlong from '@/components/BaseProlong/BaseProlong';
import { isNumber } from 'lodash';
import wizardPay from '@/mixins/billmgr/wizardPay';
export default {
  name: 'SoftProlongSinglePayments',
  components: { BaseProlong, PaymethodListBlock },
  mixins: [wizardPay],
  props: {
    text: {
      type: String,
      default: '',
    },
    tariff: {
      type: SoftTariff,
      required: true,
    },
    list: {
      type: Array,
      default: () => [],
      validator: arr => !arr.length || arr.every(i => i.k && isNumber(i.cost) && i.label),
    },
    value: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      config: {
        value: this.tariff.stored_method,
        k: 'k',
        options: [
          {
            image: '/manimg/userdata/img/cash.png',
            k: '0',
            label: 'Лицевой счет ',
          },
        ],
        disabled: false,
        required: true,
      },
    };
  },
  computed: {
    innerList() {
      const titleStart = this.title ? this.title : this.$t('title');
      return [
        {
          title: `${titleStart} ${this.tariff.pricelist}`,
          list: this.list,
          value: this.value,
          id: this.tariff.id,
        },
      ];
    },
    paymentMethods() {
      return this.$store.state.moduleBilling.modulePayment.list
        .filter(x => x.module === 'pmyandexcheckout')
        .filter(card => card.status.code === '4');
    },
    appHost() {
      return this.$store.state.moduleApp.host;
    },
    isPast() {
      return this.tariff.expiredate < Date.now();
    },
    typeOrg() {
      if (this.$store.state.moduleBilling.modulePayers.list.length) {
        return this.$store.state.moduleBilling.modulePayers.list[0].profiletype_orig === '1';
      } else return false;
    },
    provider() {
      return this.$store.state.moduleProviders.current === '3';
    },
    isView() {
      return this.provider ? this.typeOrg : true;
    },
  },
  // mounted() {
  beforeMount() {
    this.$store.dispatch('moduleBilling/modulePayment/updateList');
    this.updateType();
    this.newOptions();
    this.fetchStoredMethods();
  },
  methods: {
    async updateType() {
      return await this.$store
        .dispatch('moduleBilling/modulePayers/fetchList')
        // .then(() => (this.loading = false))
        .catch(e => this.showError(e));
    },
    newOptions() {
      return this.paymentMethods.forEach(x => {
        this.config.options.push({
          image: '/manimg/common/plugin-logo/billmanager-plugin-pmsberbank.png',
          k: x.recurring,
          label: x.name,
        });
      });
    },
    fetchStoredMethods() {
      return this.$store.dispatch('moduleVPS/fetchStoredMethods', { item_id: this.tariff.id });
    },

    onReady() {
      this.$emit('ready');
    },
    onChange(value) {
      this.$emit('change', value[this.tariff.id]);
    },
    onPayments(value) {
      this.$emit('payments', value.value);
      // console.log(value.value);
    },
    onChangeTotal(value) {
      this.$emit('change-total', value);
    },
    addCard() {
      this.startFunc = 'payment.stored_methods.add';
      this.runWizardPay().then(data => {
        if (data && data.ok && data.ok.type === 'blank' && data.ok.v) {
          this.$store.dispatch('moduleBilling/modulePayment/updateList');
          let token = localStorage.getItem('token');
          window.open(this.appHost + data.ok.v + `&auth=${token}`);
        }
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": "Продление тарифа",
    "add": "Добавить карту +",
    "alarm" : "!ВНИМАНИЕ!",
    "text": "Если на выбранном Вами способе автопродления будет недостаточно средств в момент продления услуги, услуга будет остановлена. Все остальные способы автопродления будут проигнорированы системой, даже если там достаточно средств для продления."
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.title{
  margin-top: 2rem;
}
.soft-prolong-single {}
  .debit-card {
    margin-top: 2rem;
  }
.debit-card-add {
  flexy(center, center);
  margin-top: 2rem;
  position: relative;
  height: 100%;
  min-height: 2rem;
  background: var(--debit-card-bg);
  border: 1px solid var(--main-card-border);
  border-radius: $border-radius-small;
  color: $primary-color;
  transition: background $anim-base-duration ease, box-shadow $anim-base-duration ease, color $anim-base-duration ease, border-color $anim-base-duration ease;
  padding: 1.5rem;
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    size(100%);
    absolute(0px, 0px);
    transition: opacity 0.3s;
    opacity: 0;
    background: var(--debit-card-bg-hover);
    border-radius: inherit;
  }

  &:hover {
    &::before {
      opacity: 0.2;
    }
  }
}
</style>
