<template>
  <main-card class="tariff-card">
    <template #header>
      <router-link
        :to="{ name: 'softDetail', params: { id: tariff.id } }"
        class="tariff-card__title-high big-title"
      >
        {{ `Подписка номер #${tariff.id}` }}
      </router-link>
    </template>
    <template #headerAction>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="bottom-end"
        :popper-options="popperOptions"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="tariff-card__popover"
      >
        <plain-button icon="more" class="tariff-card__more" @click="togglePopoverState" />
        <soft-context-menu slot="popover" :tariff="tariff" class="tariff-card__context-menu" />
      </v-popover>
    </template>
    <template #headerEnd>
      <main-label :color="tariff.status.color" class="tariff-card__label">
        {{ $t(`status.${tariff.status.key}`) }}
      </main-label>
    </template>
    <div class="tariff-card__content">
      <div class="tariff-card__title medium-title">
        {{ `Тариф: ${tariff.pricelist} ` }}
      </div>
      <div class="tariff-card__activity">
        <plain-button
          v-if="tariff.promise !== 'null'"
          v-tooltip="{
            content: promiseOn,
            autoHide: false,
            placement: 'top-start',
            container: false,
          }"
          icon="promise-icon"
          class="allowed-user-rights__promise"
        />
        <div v-if="![1, 5].includes(tariff.status.code)" class="tariff-card__note note-text">
          {{ tariff.autoprolong_period !== 'null' ? textProlongOn : textProlongOff }}
        </div>
        <div v-if="![1, 5].includes(tariff.status.code)" class="tariff-card__activity-actions">
          <plain-button
            v-if="isPast && tariff.status.code !== 2"
            v-tooltip="{
              content: $t('wasActiveUnderStatusOff'),
            }"
            color="del"
            icon="warn"
            class="allowed-user-rights__warn"
          />
          <plain-button
            v-if="isPastAndNeedBalance && tariff.status.code === 2"
            v-tooltip="{
              content: $t('needUpBalance'),
            }"
            color="warn"
            icon="warn"
            class="allowed-user-rights__warn"
          />
        </div>
        <div class="tariff-card__note note-text">
          {{ $t('autoprolong') }}
        </div>
        <div class="tariff-card__activity-actions">
          <soft-autoprolong
            v-tooltip="{
              content: isAutoProlong,
              autoHide: false,
              placement: 'top-end',
            }"
            :tariff="tariff"
            class="tariff-card__autoprolong"
          />
        </div>
      </div>
      <div class="tariff-card__activity">
        <div class="tariff-card__activity-actions"></div>
      </div>
    </div>
    <template #footer>
      <plain-button
        v-if="hasProlongTool"
        color="primary"
        class="tariff-card__prolong"
        @click="costAutoProlong === '0.00' ? singleProlongFree(tariff) : singleProlong(tariff)"
      >
        {{ $t('prolong.action') }}
      </plain-button>
      <!--      <plain-button-->
      <!--        v-if="isSoftPanel"-->
      <!--        color="primary"-->
      <!--        class="tariff-card__prolong"-->
      <!--        @click="goToSoftPanel"-->
      <!--      >-->
      <!--        {{ // $t('panel') }}-->
      <!--      </plain-button>-->
    </template>
  </main-card>
</template>

<script>
import prolongText from '@/mixins/prolongText';
import MainCard from '@/components/MainCard/MainCard.vue';
import MainLabel from '@/components/Labels/MainLabel.vue';
import SoftContextMenu from '../../../components/SoftContextMenu.vue';
import SoftAutoprolong from '../../../components/SoftAutoprolong';
import SoftProlongSingle from '../../../components/SoftProlongSingle.vue';
import prolongSingle from '@/mixins/billmgr/prolongSingle';
import prolongSingleFree from '@/mixins/billmgr/prolongSingleFree';
import popoverFix from '@/mixins/popoverFix';
import SoftProlongSinglePayments from '@/layouts/Soft/components/SoftProlongSinglePayments';
export default {
  name: 'TariffCard',
  components: { MainCard, MainLabel, SoftContextMenu, SoftAutoprolong },
  mixins: [prolongSingle, prolongSingleFree, popoverFix, prolongText],
  props: {
    tariff: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      popperOptions: {
        modifiers: {
          flip: {
            padding: { top: 60 },
          },
        },
      },
      costAutoProlong: 0,
      period: [],
      moduleMain: 'moduleSoft',
      singleProlongComponentFirst: SoftProlongSingle,
      singleProlongComponent: SoftProlongSinglePayments,
    };
  },
  computed: {
    isSoftPanel() {
      return this.tariff.isSoft;
    },
    isPast() {
      return this.tariff.expiredate < Date.now();
    },
    // isPastAndNeedBalance() {
    //   if (this.balance !== 0 && +this.balance > +this.tariff.item_cost) {
    //     return (
    //       Date.now() / (24 * 60 * 60 * 1000) - this.tariff.expiredate / (24 * 60 * 60 * 1000) > 0
    //     );
    //   }
    // },
    isAutoProlong() {
      return this.tariff.autoprolong_period !== 'null'
        ? 'Автопродление включено'
        : 'Включить автопродление';
    },
    tools() {
      return this.$store.state.moduleSoft.tools;
    },
    cost() {
      return this.tariff.cost;
    },
    hasProlongTool() {
      return (
        this.tariff.prolongAvailable &&
        !!this.tools &&
        !!this.tools.prolong &&
        this.tools.prolong.isEnable(this.tariff)
      );
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.popperOptions.modifiers.flip.boundary = this.$parent.$el;
    });
    if (this.$parent.$children.findIndex(i => i === this) < 2) {
      this.popperOptions.modifiers.flip.enabled = false;
    }
    if (this.hasProlongTool) this.getCost();
  },
  methods: {
    goToSoftPanel() {
      this.$store
        .dispatch('moduleSoft/fetchBillMgrToolAction', {
          func: 'gotoserver',
          id: this.tariff.id,
        })
        .then(data => {
          if (data && data.ok && data.ok.type === 'url' && data.ok.v) {
            window.open(data.ok.v);
          } else
            throw new Error(
              'Не удалось перейти в плеск панель. Возможно у Вас выключен сервер. Попробуйте обратиться в техподдержку'
            );
        })
        .catch(e => this.showError(e));
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "panel": "Панель Soft",
    "promise" : "Для услуги активирован обещанный платеж до {promise}",
    "isActiveUnder": "Действует до {date}",
    "isActiveUnderOn": "{date} продлится на {prolong} за {cost} ₽",
    "isActiveUnderOff": "Истекает {date}, {cost}  ₽ / месяц",
    "isActiveUnderProlongOff": "Истекает {date}, {cost}  ₽ / месяц",
    "wasActiveUnder": "{date} не продлился на {prolong} за {cost} ₽",
    "wasActiveUnderOff": "Истекает {date}",
    "needUpBalance": "Текущего баланса недостаточно для автоматического продления в расчётную дату, пополните баланс или настройте автоматическое списание с карты.",
    "wasActiveUnderStatusOff": "В расчетную дату на Вашем балансе было не достаточно средств, для автопродления услуги на выбранный период автопродления",
    "autoprolong": "автопродление",
    "changepricelist": {
      "title": "Смена тарифа",
      "success": "Смена тарифа прошла успешно. Данные о тарифе скоро обновятся."
    },
    "prolong": {
      "title": "Продление тарифа",
      "error": "К сожалению, что-то пошло не так",
      "success_basket": "Продление тарифа успешно добавлено в корзину",
      "success_order": "Продление тарифа прошло успешно. Данные скоро обновятся.",
      "confirm": "Оплатить",
      "free": "Активировать",
      "cancel": "Отмена",
      "order": "В корзину",
      "action": "Продлить"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.allowed-user-rights {
  &__promise {
    margin-right: 1px;
  }
}
.tariff-card {
  min-height: 100%;

   &__title {
    text-decoration: none;
    color: inherit;

    &-high {
      text-decoration: none;
      color: $primary-color;

      &:hover {
        color: darken($primary-color, 20%);
      }
    }
  }
  &__ip {
    margin-top: 2px;
    +breakpoint(sm-and-up) {
      margin-top: 20px;
    }
  }
  &__label {
    +breakpoint(sm-and-up) {
      margin-top: 0.25rem;
    }
  }
  &__note {
    //+breakpoint(sm-and-up) {
      margin-right: 0.75rem;
    //}
  }
  &__more {
    display: block;
    margin-right: -0.5rem;

    +breakpoint(sm-and-up) {
      margin-right: -1rem;
      margin-top: -2px;
    }
  }
  &__activity {
    margin-top: 1.5rem;
    +breakpoint(sm-and-up) {
      //flexy(flex-start, baseline);
      flexy(flex-start, center);
    }

    &-actions {
      flexy(flex-start, baseline);
      //margin-top: 0.75rem;

      +breakpoint(sm-and-up) {
        //margin-left: 1.5rem;
      }
    }
  }
  &__autoprolong {
    align-self: center;

    //& + ^[0]__prolong {
      //margin-left: 1.5rem;
    //}
  }

  &__prolong {
    margin-right: 1.5rem !important;
  }

  &__context-menu {
    width: calc(100vw - 3.25rem);

    +breakpoint(sm-and-up) {
      width: 260px;
    }
  }
}
</style>
